import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
const InfoModal = ({
  show,
  change,
  min,
  sec,
  timer,
  payUrl,
  apiCall,
  setShowThree,
  setShowFour,
}) => {
  return (
    <>
      <Modal
        className="modal fade modal-bottom-to-top"
        id="lab-slide-bottom-popup"
        show={show}
        onHide={change}
        backdropClassName="modal-backdrop"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title></Modal.Title>{" "}
        </Modal.Header>
        <div className="lab-modal-body">
          <div className="content_new">
            <h1>Did you know?</h1>
            <p className="desc">
              "An impressive 65% of users who utilized our floor plan feature
              with HomePlanner Ai have seamlessly transitioned to construction,
              sticking to their original plan without needing any
              modifications!"
            </p>
            <div className="box_img">
              <img
                loading="lazy"
                src="https://mailer-assets.makemyhouse.com/form-type-images/family-with-home.png"
                alt="Third slide"
              />
            </div>
            <p className="desc_new_c">
              We want to bring your dream home to life so we are offering an{" "}
              <span className="bolder-f">additional 12.5% </span>discount on
              your <span className="bolder-f">2 option plan</span> package.
            </p>
            {timer ? (
              <>
                <a
                //   onClick={() => apiCall(payUrl, "Offer")}
                  onClick={() => {setShowFour(true);
                  setShowThree(false);
                  }}
                  id="firstPayment"
                  className="cursor_pointer"
                >
                  <button className="btn btn-sm btn_pay w-100">
                    Grab Offer Now
                  </button>
                </a>
                <div>
                  Time Remaining: {min}:{sec}
                </div>
              </>
            ) : (
              <button
                className="btn btn-sm btn_pay w-100 mt-2"
                onClick={change}
              >
                Get Plan
              </button>
            )}
          </div>
        </div>
      </Modal>
    </>
  );
};
export default InfoModal;
