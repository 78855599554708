import React, { useEffect, useState } from "react";
import Carousel from "react-bootstrap/Carousel";
import Accordion from "react-bootstrap/Accordion";
import Modal from "react-bootstrap/Modal";
import "./payPage.css";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { OverlayTrigger, Popover, Collapse } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.css";
import InfoModal from "./InfoModal";
import DiscountModal from "./DiscountModal";

const StepThree = () => {
  const [open, setOpen] = useState(false);
  const location = useLocation();
  const { slug } = useParams();
  const popover = (
    <Popover id="popover-basic">
      <Popover.Body>You will receive one Floor Plan option.</Popover.Body>
    </Popover>
  );

  const popoverSecond = (
    <Popover id="popover-basic-second">
      <Popover.Body>
        You will receive Entirely different Two Floor Plan options
      </Popover.Body>
    </Popover>
  );

  const searchParams = new URLSearchParams(location.search);
  const isd = searchParams.get("isd") || "91";
  const mobile = searchParams.get("mobile");
  // const slug = searchParams.get("slug");
  // const responseId = searchParams.get("responseId");
  const responseId = searchParams.get("Rx");

  // const formattedIsd = isd?.replace("+", "");
  const [show, setShow] = useState(false);

  // For PaymentLink
  const [secondPayUrl, setSecondPayUrl] = useState();
  const [offerPayUrl, setOfferPayUrl] = useState();
  // End

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [showTwo, setShowTwo] = useState(false);
  const handleCloseTwo = () => setShowTwo(false);
  const handleShowTwo = () => setShowTwo(true);

  // For Did You Know Modal Handlers
  const [showThree, setShowThree] = useState(false);
  const handleShowThree = () => setShowThree(true);
  const handleCloseThree = () => setShowThree(false);
  //  End
  // For Offer Modal Handlers
  const [showFour, setShowFour] = useState(false);
  const handleShowFour = () => setShowFour(true);
  const handleCloseFour = () => setShowFour(false);
  // End

  const [data, setData] = useState(null);
  const [payURL, setPayURL] = useState(null);
  const [futureDate, setFutureDate] = useState(null);

  const formatDate = (date) => {
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Months are zero-based
    const year = date.getFullYear();

    return `${day}-${month}-${year}`;
  };

  useEffect(() => {
    const currentDate = new Date();
    const futureDate = new Date(currentDate.setDate(currentDate.getDate() + 5));
    setFutureDate(futureDate);
  }, []);

  // Make the API call for CheckOut Details
  const getCheckOutDetails = (currency) => {
    fetch(
      `https://forms-api.makemyhouse.com/checkout-details?&currency=${currency}&slug=${slug}`
    )
      .then((response) => response.json())
      .then((resultData) => {
        console.log("data from api call", resultData);
        const isUS = window.location.href.includes("/us");

        // For First Offer payment Link
        const url = `https://www.makemyhouse.com/payment?type=Option1&currency=${
          resultData.currency
        }&amount=${resultData.after_offer_amount}&mobile=${isd?.replace(
          "+",
          ""
        )}${mobile}&responseId=${responseId}&brand=${
          isUS ? "homeplannerUs" : "homeplanner"
        }`;

        // For Second Offer
        if (resultData.second_after_offer_amount) {
          const url_second = `https://www.makemyhouse.com/payment?type=Option2&currency=${
            resultData.currency
          }&amount=${
            resultData.second_after_offer_amount
          }&mobile=${isd?.replace(
            "+",
            ""
          )}${mobile}&responseId=${responseId}&brand=${
            isUS ? "homeplannerUs" : "homeplanner"
          }`;

          const offer_url = `https://www.makemyhouse.com/payment?type=Option2_Offer&currency=${
            resultData.currency
          }&amount=349&mobile=${isd?.replace(
            "+",
            ""
          )}${mobile}&responseId=${responseId}&brand=${
            isUS ? "homeplannerUs" : "homeplanner"
          }`;

          setSecondPayUrl(url_second);
          setOfferPayUrl(offer_url);
        }

        // End
        //  `https://www.makemyhouse.com/payment?currency=${
        //     resultData.currency
        //   }&amount=${resultData.after_offer_amount}&mobile=${isd?.replace(
        //     "+",
        //     ""
        //   )}${mobile}&responseId=${responseId}&brand=`;

        setPayURL(url);
        setData(resultData);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };

   const updatePlaneInResponse = (locationUrl, packageType) => {
     fetch(`https://forms-api.makemyhouse.com/update/${responseId}`, {
       // fetch(`http://localhost:4000/update/${responseId}`, {
       method: "POST",
       headers: {
         "Content-Type": "application/json",
       },
       body: JSON.stringify({ packageType }),
     })
       .then((response) => response.json())
       .then((responseData) => {
         console.log("response update api: ", responseData);
       });
     console.log("package Type: ", packageType);
     window.location.href = locationUrl;
   };
  useEffect(() => {
    var currency;
    // console.log("isd", isd.includes("91"));
    // if (isd === "+91") {
    if (isd?.includes("91")) {
      currency = "INR";
    } else {
      currency = "USD";
    }
    console.log("currency", currency);
    if (currency) {
      getCheckOutDetails(currency);
    }
  }, []);
  // This is For Plans
  const [planValue, setPlanValue] = useState("one");

  const handleGroup1Change = (event) => {
    setPlanValue(event.target.value);
  };
  // End

  // For Event On Browser Back Button
  const [isRunning, setIsRunning] = useState(false);
  const [time, setTime] = useState(600); // 10 minutes in seconds
  const [offerCookie, setOfferCookie] = useState(
    localStorage.getItem("offerCookie") || "0"
  );

  const minutes = Math.floor(time / 60);
  const seconds = time % 60;

  useEffect(() => {
    //  localStorage.setItem("offerCookie", "0");
    if (!localStorage.getItem("timerLastRunDate")) {
      console.log("iam inn");
      localStorage.setItem("offerCookie", "0");
    }
    const RemainingTime = localStorage.getItem("RemainingTimerTime");
    if (RemainingTime) {
      setTime(RemainingTime);
      setIsRunning(true);
    }
  }, []);

  useEffect(() => {
    let timerId;
    // console.log("isRunning", isRunning, "time", time);
    if (isRunning && time > 0) {
      timerId = setInterval(() => {
        setTime((prevTime) => (prevTime > 0 ? prevTime - 1 : 0));
        localStorage.setItem("RemainingTimerTime", time);
      }, 1000);
    } else if (time === 0) {
      setIsRunning(false);
    }
    return () => clearInterval(timerId);
  }, [isRunning, time]);

  useEffect(() => {
    if (localStorage.getItem("offerCookie")) {
      console.log("offer value cookie", localStorage.getItem("offerCookie"));
      setOfferCookie(localStorage.getItem("offerCookie"));
    }
  }, [localStorage.getItem("offerCookie")]);

  const preventBack = () => {
    const backEventTrace = offerCookie;
    console.log(
      "Back button pressed. offerCookie:",
      backEventTrace,
      "Time:",
      time
    );

    if (time == 0) {
      console.log("timer running and offer");
      setShowThree(true);
      setShowFour(false);
    } else {
      if (backEventTrace == "0") {
        console.log("timer running and offer valyue 0 ");
        localStorage.setItem("offerCookie", "1");
        if (!isRunning) {
          const currentDate = new Date().toLocaleDateString();
          const currentTime = new Date().getTime();
          if (!localStorage.getItem("timerLastRunDate")) {
            localStorage.setItem("timerLastRunDate", currentDate);
          }

          if (!localStorage.getItem("timerLastRunTime")) {
            localStorage.setItem("timerLastRunTime", currentTime);
          }
          setIsRunning(true);
        }

        const currentDate = new Date().toLocaleDateString();
        const currentTime = new Date().getTime();

        const lastRunDate = localStorage.getItem("timerLastRunDate") || "";
        const lastRunTime =
          parseInt(localStorage.getItem("timerLastRunTime")) || 0;

        const TEN_MINUTES = 10 * 60 * 1000; // 10 minutes in milliseconds

        if (
          currentDate <= lastRunDate &&
          currentTime <= lastRunTime + TEN_MINUTES
        ) {
          setShowThree(false);
          setShowFour(true);
        }else{
          setIsRunning(false)
           setShowThree(true);
           setShowFour(false);
        }
      } else if (backEventTrace == "1") {
        console.log("timer running and offer valyue 1 ");
        localStorage.setItem("offerCookie", "0");
        const currentDate = new Date().toLocaleDateString();
        const currentTime = new Date().getTime();

        const lastRunDate = localStorage.getItem("timerLastRunDate") || "";
        const lastRunTime =
          parseInt(localStorage.getItem("timerLastRunTime")) || 0;

        const TEN_MINUTES = 10 * 60 * 1000; // 30 minutes in milliseconds

        if (
          currentDate <= lastRunDate &&
          currentTime <= lastRunTime + TEN_MINUTES
        ) {
          setShowThree(true);
          setShowFour(false);
        } else {
          setIsRunning(false);
          setShowThree(true);
          setShowFour(false);
        }
      }
    }
  };

  const navigate = useNavigate();

  useEffect(() => {
    localStorage.setItem(
      "isBackCheckoutUrl",
      window.location.pathname + window.location.search
    );
  }, [navigate]);

  useEffect(() => {
    if (localStorage.getItem("isRedirected") == 1) {
      console.log("page redirected from login.... ");
      preventBack();
    }
  }, []);
  useEffect(() => {
    console.log("showFour ", showFour, "showThree ", showThree);
  }, [showFour,showThree]);

  // End

  return (
    <>
      {data !== null && (
        <section className="w-100 main-section">
          <div className="col-sm-3 main-page-con m-auto">
            <div className="row">
              <div className="col-sm-12 mb-3 main-slider">
                <Carousel data-bs-theme="dark">
                  <Carousel.Item>
                    <img
                      loading="lazy"
                      className="d-block w-100"
                      src="https://mailer-assets.makemyhouse.com/checkout-page-images/b-1.png"
                      alt="First slide"
                    />
                    <Carousel.Caption></Carousel.Caption>
                  </Carousel.Item>
                  <Carousel.Item>
                    <img
                      loading="lazy"
                      className="d-block w-100"
                      src="https://mailer-assets.makemyhouse.com/checkout-page-images/b-2.png"
                      alt="Second slide"
                    />
                    <Carousel.Caption></Carousel.Caption>
                  </Carousel.Item>
                  <Carousel.Item>
                    <img
                      loading="lazy"
                      className="d-block w-100"
                      src="https://mailer-assets.makemyhouse.com/checkout-page-images/b-3.png"
                      alt="Third slide"
                    />
                    <Carousel.Caption></Carousel.Caption>
                  </Carousel.Item>
                </Carousel>
              </div>

              <section className="price-main mt-3">
                <div className="" style={{ padding: "0px 10px 20px 10px" }}>
                  <div className="col-12">
                    <div className="heading mt-0">
                      <h3>
                        <b>Your 2D Floor Plan is ready !</b>
                      </h3>
                    </div>
                    <p className="mb-3">
                      Experience a Conceptualized 2D floor Plan Satisfying 95%
                      of Your Given Requirement.
                    </p>
                    <p className="mb-1">
                      <strong>Delivery Time :</strong> Within 4 working hours
                    </p>
                    <p className="mb-1">
                      <strong>Delivery Via :</strong> Softcopy (Email ,
                      Whatsapp)
                    </p>
                  </div>
                  <div className="plans mt-2 text center">
                    {/* For First Plane  */}
                    <label className="plan basic-plan" htmlFor="basic">
                      <input
                        checked={planValue === "one"}
                        type="radio"
                        name="plan"
                        id="basic"
                        value="one"
                        onChange={handleGroup1Change}
                      />
                      <div className="plan-content">
                        <div className="row">
                          <div className="col-5">
                            <p className="title_price">
                              <strong>One design option Plan:</strong>
                            </p>
                          </div>

                          <div className="col-7">
                            <div className="price showprice d-flex">
                              <div>
                                {data.symbol}
                                {data.after_offer_amount}/-
                              </div>{" "}
                              &nbsp;{" "}
                              <s style={{ color: "#fff" }}>
                                {data.symbol}
                                {data.original_amount}
                              </s>
                              <div className="prsnt"> {data.discount}%</div>{" "}
                              <font>OFF</font>
                              <span
                                data-container="body"
                                data-toggle="popover"
                                data-placement="top"
                                data-content="Vivamus sagittis lacus vel augue laoreet rutrum faucibus."
                              ></span>
                              <OverlayTrigger
                                trigger="click"
                                placement="bottom"
                                overlay={popover}
                              >
                                <a variant="secondary">
                                  <InfoOutlinedIcon
                                    style={{
                                      fontSize: "14px",
                                      marginLeft: "4px",
                                    }}
                                  ></InfoOutlinedIcon>
                                </a>
                              </OverlayTrigger>
                            </div>

                            <div className="price expire">
                              <div>
                                {data.symbol}
                                {data.original_amount}/-
                              </div>
                              <div>
                                {data.symbol}
                                {data.original_amount}
                              </div>
                              <sup> &nbsp; {data.discount}%</sup>&nbsp;{" "}
                              <font>OFF</font>
                            </div>
                          </div>
                        </div>
                      </div>
                    </label>
                    {/* End First Plan Div  */}
                    <style>
                      {`
                        .right-float-white {
                          color: white !important;
                          display: flex;
                          justify-content: end;
                          align-items: center;
                        }
                        .cursor_pointer{
                          cursor:pointer !important;
                        }
                      `}
                    </style>
                    <div
                      onClick={() => setOpen(!open)}
                      aria-controls="collapse-second-plan"
                      aria-expanded={open}
                      className="right-float-white cursor_pointer"
                    >
                      +1 more
                    </div>

                    {/* For Second Plane  */}
                    {data.second_after_offer_amount && (
                      <Collapse in={open}>
                        <div id="collapse-second-plan">
                          <label
                            className="plan complete-plan"
                            htmlFor="complete"
                          >
                            <input
                              type="radio"
                              checked={planValue === "two"}
                              id="complete"
                              name="plan"
                              value="two"
                              onChange={handleGroup1Change}
                            />
                            <div className="plan-content">
                              <div className="row">
                                <div className="col-5">
                                  <p className="title_price">
                                    <strong>Two design option Plan:</strong>
                                  </p>
                                </div>

                                <div className="col-7">
                                  <div className="price showprice d-flex">
                                    <div>
                                      {data.symbol}
                                      {data.second_after_offer_amount}/-
                                    </div>{" "}
                                    &nbsp;{" "}
                                    <s style={{ color: "#fff" }}>
                                      {data.symbol}
                                      {data.second_original_amount}
                                    </s>
                                    <div className="prsnt">
                                      {" "}
                                      {data.second_discount}%
                                    </div>{" "}
                                    <font>OFF</font>
                                    <OverlayTrigger
                                      trigger="click"
                                      placement="bottom"
                                      overlay={popoverSecond}
                                    >
                                      <a variant="secondary">
                                        <InfoOutlinedIcon
                                          style={{
                                            fontSize: "14px",
                                            marginLeft: "4px",
                                          }}
                                        ></InfoOutlinedIcon>
                                      </a>
                                    </OverlayTrigger>
                                  </div>
                                  <div className="price expire">
                                    <div>
                                      {data.symbol}
                                      {data.second_original_amount}/-
                                    </div>
                                    <div>
                                      {data.symbol}
                                      {data.second_original_amount}
                                    </div>
                                    <sup> &nbsp; {data.second_discount}%</sup>
                                    &nbsp; <font>OFF</font>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </label>
                        </div>
                      </Collapse>
                    )}

                    {/* End Second Plan Div  */}
                  </div>
                  <div className="col-12 ">
                    <p
                      style={{
                        textAlign: "center",
                        fontStyle: "italic",
                        color: "#14f7c1",
                      }}
                    >{`(Offer Valid Till ${formatDate(futureDate)})`}</p>
                  </div>
                  <div className="col-12">
                    <div className="price w-100">
                      {/* <a className="showprice w-100" href={payURL}>
                        <button className="btn btn-sm btn_pay w-100">
                          Pay Now
                        </button>
                      </a> */}
                      {planValue === "one" && (
                        <span
                          className="showprice w-100"
                          onClick={() =>
                            updatePlaneInResponse(payURL, "Option1")
                          }
                        >
                          <button className="btn btn-sm btn_pay w-100">
                            Pay Now
                          </button>
                        </span>
                      )}

                      {planValue === "two" && (
                        <span
                          className="showprice w-100"
                          onClick={() =>
                            updatePlaneInResponse(secondPayUrl, "Option2")
                          }
                        >
                          <button className="btn btn-sm btn_pay w-100">
                            Pay Now
                          </button>
                        </span>
                      )}
                    </div>
                  </div>
                </div>
              </section>

              <section className="w-100 mt-3 mb-3">
                {/* DID You Know Modal Start  */}
                <InfoModal
                  show={showThree}
                  setShowThree={setShowThree}
                  setShowFour={setShowFour}
                  change={handleCloseThree}
                  min={minutes}
                  sec={seconds}
                  timer={isRunning}
                  payUrl={offerPayUrl}
                  apiCall={updatePlaneInResponse}
                />
                {/* DID You Know Modal End  */}
                {/* Offer modal Start */}
                <DiscountModal
                  show={showFour}
                  change={handleCloseFour}
                  min={minutes}
                  sec={seconds}
                  payUrl={offerPayUrl}
                  apiCall={updatePlaneInResponse}
                  timer={isRunning}
                />
                {/* Offer modal End */}
                <div className="col-sm-12">
                  <div className="row">
                    <div className="col-12 mb-3">
                      <div className="heading">
                        <h2 className="text-center">
                          <b>Sample Delivery Plans</b>
                        </h2>
                      </div>
                    </div>
                    <div className="col-6 glry-img">
                      <img
                        loading="lazy"
                        className="d-block w-100"
                        src="https://mailer-assets.makemyhouse.com/checkout-page-images/s-1.jpg"
                        alt="Third slide"
                        onClick={handleShow}
                      />
                      {/* For Image One  */}
                      <Modal show={show}>
                        <h2 className="close-mdl" onClick={handleClose}>
                          ╳
                        </h2>
                        <img
                          loading="lazy"
                          className="d-block w-100"
                          src="https://mailer-assets.makemyhouse.com/checkout-page-images/s-1.jpg"
                          alt="Third slide"
                        />
                      </Modal>
                    </div>
                    <div className="col-6 glry-img">
                      <img
                        loading="lazy"
                        className="d-block w-100"
                        src="https://mailer-assets.makemyhouse.com/checkout-page-images/s-2.jpg"
                        alt="Third slide"
                        onClick={handleShowTwo}
                      />

                      {/* For Image Two  */}
                      <Modal show={showTwo}>
                        <h2 className="close-mdl" onClick={handleCloseTwo}>
                          ╳
                        </h2>
                        <img
                          loading="lazy"
                          className="d-block w-100"
                          src="https://mailer-assets.makemyhouse.com/checkout-page-images/s-2.jpg"
                          alt="Third slide"
                        />
                      </Modal>
                    </div>
                  </div>
                </div>
              </section>

              <div className="advantages p-3 pt-0 pb-0">
                <div className="heading">
                  <h2 className="text-center">
                    <b>Advantages</b>
                  </h2>
                </div>
                <div className="row mt-4">
                  <div className="col-6 text-center">
                    <div className="advantages-bx">
                      <img
                        loading="lazy"
                        src="https://mailer-assets.makemyhouse.com/checkout-page-images/compass.png"
                        className="img-fluid"
                        alt=""
                      ></img>
                      <h6>
                        Vastu compliant <br></br> plans
                      </h6>
                    </div>
                  </div>
                  <div className="col-6 text-center">
                    <div className="advantages-bx">
                      <img
                        loading="lazy"
                        src="https://mailer-assets.makemyhouse.com/checkout-page-images/architect.png"
                        className="img-fluid"
                        alt=""
                      ></img>
                      <h6>Experienced design professionals</h6>
                    </div>
                  </div>
                  <div className="col-6 text-center">
                    <div className="advantages-bx">
                      <img
                        loading="lazy"
                        src="https://mailer-assets.makemyhouse.com/checkout-page-images/design.png"
                        className="img-fluid"
                        alt=""
                      ></img>
                      <h6>
                        100% Quality <br></br> Assurance
                      </h6>
                    </div>
                  </div>
                  <div className="col-6 text-center">
                    <div className="advantages-bx">
                      <img
                        loading="lazy"
                        src="https://mailer-assets.makemyhouse.com/checkout-page-images/structure.png"
                        className="img-fluid"
                        alt=""
                      ></img>
                      <h6>
                        Project <br></br> Trackability
                      </h6>
                    </div>
                  </div>
                </div>
              </div>

              <div className="faq p-3 pt-0 row">
                <div className="heading px-0">
                  <h2 className="text-center">
                    <b>Frequently Asked Questions</b>
                  </h2>
                </div>
                <div className="col-sm-12">
                  <Accordion defaultActiveKey="0">
                    <Accordion.Item eventKey="1">
                      <Accordion.Header>
                        What is a 2D floor plan service?
                      </Accordion.Header>
                      <Accordion.Body>
                        A conceptualized 2D floor plan is a 2-dimensional floor
                        plan or a technical drawing which is Vaastu compliant,
                        created as per the house building requirements by Home
                        planner Ai.
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="2">
                      <Accordion.Header>
                        What is the process after raising a request for 2D Floor
                        Plan?
                      </Accordion.Header>
                      <Accordion.Body>
                        After the request is raised with us and payment is done
                        successfully, you will receive a conceptual 2D floor
                        plan according to the details provided by you within 4-5
                        working hours on your email. You can thereafter contact
                        us for further consultation and customization.
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="3">
                      <Accordion.Header>
                        What will be delivered as part of 2D floor plan service?
                      </Accordion.Header>
                      <Accordion.Body>
                        You will get 1 floor plan design option if 1design
                        option packaged is purchased or you will get 2 floor
                        plan design option if 2 design option packaged is
                        purchased.
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="4">
                      <Accordion.Header>
                        Will it be possible to make changes in the 2D Floor
                        Plan?
                      </Accordion.Header>
                      <Accordion.Body>
                        Yes, it will be possible to make changes to the 2D Floor
                        Plan provided with some additional charges. You can also
                        avail our detailed floor plan service with unlimited
                        changes, feel free to mail us at{" "}
                        <a href="mailto:contact@makemyhouse.com">
                          contact@makemyhouse.com
                        </a>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="5">
                      <Accordion.Header>
                        What is the cost of availing a 2D Floor plan service
                      </Accordion.Header>
                      <Accordion.Body>
                        The cost of a sample 2D floor plan is Rs. 299/- (80% OFF
                        on Rs.1499/-) for 1 option and Rs. 399/- (80% OFF on
                        Rs.1999/-) for 2 option plan
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="6">
                      <Accordion.Header>
                        Do you provide other Architectural services?
                      </Accordion.Header>
                      <Accordion.Body>
                        Yes, we provide all other architectural services related
                        to house designing like 3D-2D Exterior Design, Working
                        Dwgs, Structural Dwgs, Interior Designs, Walkthrough and
                        other Engineering drawings etc.
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                </div>
              </div>
              <section className="price-main price-main-g mt-3 mb-5">
                <div className="row p-4 pt-2">
                  <div className="col-3">
                    <img
                      loading="lazy"
                      src="https://mailer-assets.makemyhouse.com/checkout-page-images/money.png"
                      width="100%"
                      alt="currency"
                    ></img>
                  </div>
                  <div className="col-9">
                    <div className="heading mt-0 mb-0">
                      <h3>
                        <b>100% Money Back Guarantee</b>
                      </h3>
                    </div>
                    <p className="mb-1">
                      We Provide 100% Written Money Back Guarantee on Designs if
                      not Satisfied.
                    </p>
                  </div>
                </div>
              </section>

              <div className="col-sm-12 mb-3 main-slider p-0">
                <Carousel data-bs-theme="dark">
                  <Carousel.Item>
                    <div className="carousel-item active">
                      <div className="testimonial-main text-center">
                        <img
                          loading="lazy"
                          src="https://cdn-icons-png.flaticon.com/512/3135/3135715.png"
                          width="40px"
                          className="mb-1"
                          alt=""
                        ></img>
                        <p className="mb-0">
                          <b>Sulabh Bhargava, Indore </b>
                        </p>
                        <img
                          loading="lazy"
                          src="https://mailer-assets.makemyhouse.com/checkout-page-images/5-star.png"
                          width="100px"
                          alt=""
                        ></img>
                        <p>
                          Ar. Rakshendra Solanki Er. Ajay Dangar Ar. Rakhi Gour
                          are one of the best architects in makemyhouse. I am
                          refering to take services from the make my house
                          company to get proper design concept and timely
                          completion of the project
                        </p>
                      </div>
                    </div>
                    <Carousel.Caption></Carousel.Caption>
                  </Carousel.Item>
                  <Carousel.Item>
                    <div className="carousel-item active">
                      <div className="testimonial-main text-center">
                        <img
                          loading="lazy"
                          src="https://cdn-icons-png.flaticon.com/512/3135/3135715.png"
                          width="40px"
                          className="mb-1"
                          alt=""
                        ></img>
                        <p className="mb-0">
                          <b>Gaurav Kapahi, Chandigarh</b>
                        </p>
                        <img
                          loading="lazy"
                          src="https://mailer-assets.makemyhouse.com/checkout-page-images/5-star.png"
                          width="100px"
                          alt=""
                        ></img>
                        <p>
                          Got best support from the elevation team. I have asked
                          for changes for more than 7-8 times. Every time they
                          responded very well. want to mention specially Mr
                          Sandeep, Mr Rishab, Mr Jackee, Mr Aditya Ms Fhreen for
                          their support.
                        </p>
                      </div>
                    </div>
                    <Carousel.Caption></Carousel.Caption>
                  </Carousel.Item>
                  <Carousel.Item>
                    <div className="carousel-item active">
                      <div className="testimonial-main text-center">
                        <img
                          loading="lazy"
                          src="https://cdn-icons-png.flaticon.com/512/3135/3135715.png"
                          width="40px"
                          className="mb-1"
                          alt=""
                        ></img>
                        <p className="mb-0">
                          <b>Gurnaam Choudhary, Panchkula, Haryana </b>
                        </p>
                        <img
                          loading="lazy"
                          src="https://mailer-assets.makemyhouse.com/checkout-page-images/5-star.png"
                          width="100px"
                          alt=""
                        ></img>
                        <p>
                          Exterior Front and side Elevation . Fabulous service
                          provided by team . Supportive Team member . I
                          recommend MMH team for any kind of construction
                          related work . Thanks to all team member specially
                          Nidhi Yadav, Sadhna , and Sandeep Maurya .
                        </p>
                      </div>
                    </div>
                    <Carousel.Caption></Carousel.Caption>
                  </Carousel.Item>
                </Carousel>
              </div>

              <section className="mt-4 w-100">
                <div className="row p-3 pb-4 bg-secondary m-4">
                  <div className="col-12">
                    <div className="heading px-0">
                      <h3 className="text-center">
                        Guaranteed <b>Safe</b> Checkout
                      </h3>
                    </div>
                  </div>
                  <div className="col-12 text-center">
                    <a href="">
                      <img
                        loading="lazy"
                        src="https://mailer-assets.makemyhouse.com/checkout-page-images/pay-card.png"
                        width="100%;"
                        className="mt-3"
                        alt=""
                      ></img>
                    </a>
                  </div>
                </div>
              </section>

              <section className="mb-4 w-100">
                <div className="row pt-2">
                  <div className="col-12">
                    <div className="heading px-0">
                      <h3 className="text-center">
                        <b>As Featured in</b>
                      </h3>
                    </div>
                  </div>
                  <div className="col-12 text-center">
                    <a href="">
                      <img
                        loading="lazy"
                        src="https://mailer-assets.makemyhouse.com/checkout-page-images/db-logo.png"
                        width="30%;"
                        alt=""
                      ></img>
                    </a>
                    <a href="">
                      <img
                        loading="lazy"
                        src="https://mailer-assets.makemyhouse.com/checkout-page-images/zeenews.png"
                        width="30%;"
                        alt=""
                      ></img>
                    </a>
                    <a href="">
                      <img
                        loading="lazy"
                        src="https://mailer-assets.makemyhouse.com/checkout-page-images/The_Pioneer-logo.png"
                        width="30%;"
                        alt=""
                      ></img>
                    </a>
                    <br></br>
                    <a href="">
                      <img
                        loading="lazy"
                        src="https://mailer-assets.makemyhouse.com/checkout-page-images/dna-logo.png"
                        width="30%;"
                        alt=""
                      ></img>
                    </a>
                    <a href="">
                      <img
                        loading="lazy"
                        src="https://mailer-assets.makemyhouse.com/checkout-page-images/outlookmagzine.png"
                        width="30%;"
                        alt=""
                      ></img>
                    </a>
                  </div>
                </div>
              </section>

              <section className="mt-3">
                <div className="row">
                  <div className="col-12">
                    <div className="accolades bg-light p-3 m-2 w-75 m-auto text-center">
                      <img
                        loading="lazy"
                        src="https://mailer-assets.makemyhouse.com/checkout-page-images/google-r.png"
                        width="70%"
                        alt=""
                      ></img>
                    </div>
                    <br></br>
                    <div className="accolades bg-light p-3 m-2 w-75 m-auto mt-2">
                      <div className="row">
                        <div className="col-4 text-center">
                          <img
                            loading="lazy"
                            src="https://mailer-assets.makemyhouse.com/checkout-page-images/award_realestate.webp"
                            width="65%"
                            alt=""
                          ></img>
                        </div>
                        <div className="col-8">
                          <h6 className="text-dark">
                            <b>
                              The Economic Times Real Estate Conclave & Awards
                              2022
                            </b>
                          </h6>
                        </div>
                      </div>
                    </div>
                    <br></br>
                    <div className="accolades bg-light p-3 m-2 w-75 m-auto mt-2">
                      <div className="row p-2">
                        <div className="col-4">
                          <img
                            loading="lazy"
                            src="https://mailer-assets.makemyhouse.com/checkout-page-images/award_red.webp"
                            width="100%"
                            alt=""
                          ></img>
                        </div>
                        <div className="col-8">
                          <h6 className="text-dark">
                            <b>Red Achievers Awards 2021</b>
                          </h6>
                        </div>
                      </div>
                    </div>
                    <br></br>
                    <div className="accolades bg-light p-3 m-2 w-75 m-auto mt-2 mb-2">
                      <div className="row p-2">
                        <div className="col-4">
                          <img
                            loading="lazy"
                            src="https://mailer-assets.makemyhouse.com/checkout-page-images/startupaward.png"
                            width="100%"
                            alt=""
                          ></img>
                        </div>
                        <div className="col-8">
                          <h6 className="text-dark mb-0">
                            <b>Startup Awards 2022</b>
                          </h6>
                          <p className="mb-0 text-dark">
                            Awarded As Top 25 Startups in Indore by *Government
                            of India*
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>

              <div className="my-3 px-2 mt-4">
                <div className="heading">
                  <h3 className="text-sm text-center">
                    {" "}
                    <b>*Disclaimer</b>{" "}
                  </h3>
                </div>
                <p className="text-sm text-center">
                  <i>
                    These conceptual 2D Floor Plans are not “Good for
                    Construction”. To get “Good for Construction” designs,
                    please get in touch with our expert Architect and Engineer.
                    To avail detail plans and packages, Feel Free to contact us
                    at{" "}
                    <a href="mailto:contact@makemyhouse.com">
                      contact@makemyhouse.com
                    </a>{" "}
                  </i>
                </p>
              </div>

              <section className="w-100">
                <div className="">
                  <div className="col-12 text-center mb-1">
                    <a href="https://www.facebook.com/MakeMyHousePlans/">
                      <img
                        loading="lazy"
                        src="https://mailer-assets.makemyhouse.com/checkout-page-images/fb.png"
                        width="30px;"
                        alt=""
                      ></img>
                    </a>
                    &nbsp;
                    <a href="https://www.instagram.com/makemyhouseindore/?hl=en">
                      <img
                        loading="lazy"
                        src="https://mailer-assets.makemyhouse.com/checkout-page-images/insta.png"
                        width="30px;"
                        alt=""
                      ></img>
                    </a>
                    &nbsp;
                    <a href="https://www.youtube.com/c/Makemyhouse/videos">
                      <img
                        loading="lazy"
                        src="https://mailer-assets.makemyhouse.com/checkout-page-images/youtube.png"
                        width="30px;"
                        alt=""
                      ></img>
                    </a>
                    &nbsp;
                    <a href="https://www.linkedin.com/company/make-my-house/?viewAsMember=true">
                      <img
                        loading="lazy"
                        src="https://mailer-assets.makemyhouse.com/checkout-page-images/linkdin.png"
                        width="30px;"
                        alt=""
                      ></img>
                    </a>
                    &nbsp;
                    <a href="https://twitter.com/makemyhouse?s=09">
                      <img
                        loading="lazy"
                        src="https://mailer-assets.makemyhouse.com/checkout-page-images/tw.png"
                        width="30px;"
                        alt=""
                      ></img>
                    </a>
                  </div>
                </div>
              </section>
              <section className="w-100 mt-3">
                <div className="text-center">
                  <div className="col-12 text-center mb-1">
                    <a href="https://www.makemyhouse.com/page/t&c">
                      T&C &nbsp; |
                    </a>

                    <a href="https://www.makemyhouse.com/page/privacy-policy">
                      &nbsp; Privacy Policy &nbsp; |
                    </a>

                    <a href="https://www.makemyhouse.com/page/disclaimer">
                      &nbsp; Disclaimer
                    </a>
                  </div>
                </div>
              </section>
              <section className="w-100">
                <div className="row">
                  <div className="col-12 text-center mb-2 text-white">
                    <font>
                      An initiative by{" "}
                      <a href="https://www.makemyhouse.com/">Makemyhouse.com</a>
                    </font>
                  </div>
                </div>
              </section>
              <section className="w-100 mb-5">
                <div className="row">
                  <div className="col-12 text-center text-white">
                    <font>
                      © Copyright 2024{" "}
                      <a href="https://www.makemyhouse.com/">Make my house</a>{" "}
                      All Rights Reserved.
                    </font>
                  </div>
                </div>
              </section>
            </div>
          </div>
          {planValue === "one" && (
            <a
              onClick={() => updatePlaneInResponse(payURL, "Option1")}
              id="firstPayment"
              className="cursor_pointer"
            >
              <div className="pay-button ">
                Pay {data.symbol}
                {data.after_offer_amount} To Unlock Now
              </div>
            </a>
          )}

          {planValue === "two" && (
            <a
              onClick={() => updatePlaneInResponse(secondPayUrl, "Option2")}
              id="secondPayment"
              className="cursor_pointer"
            >
              <div className="pay-button">
                Pay {data.symbol}
                {data.second_after_offer_amount} To Unlock Now
              </div>
            </a>
          )}
        </section>
      )}
    </>
  );
};

export default StepThree;
